import LocalTime from '@/utility/local_time';
import mutations from './mutations';

// eslint-disable-next-line import/order

describe('mutations', () => {
  describe('setExportRange', () => {
    it('should update state', () => {
      const STATE = {
        beginTime: null,
        endTime: null,
      };

      mutations.setExportRange(STATE, {
        begin: new LocalTime(0).useUniTimezone(),
        end: new LocalTime(1).useUniTimezone(),
      });
      expect(STATE.beginTime.timestamp).toEqual(0);
      expect(STATE.beginTime.tzoffs).toEqual(0);
      expect(STATE.beginTime.localTimestamp).toEqual(0);
      expect(STATE.endTime.timestamp).toEqual(1);
      expect(STATE.endTime.tzoffs).toEqual(0);
      expect(STATE.endTime.localTimestamp).toEqual(1);
    });
  });
  describe('setStatus', () => {
    it('should set state.status if status is valid', () => {
      const STATE = {
        status: 'NORMAL'
      };

      mutations.setStatus(STATE, 'ERROR');

      expect(STATE.status).toBe('ERROR');
    });
    it('should not set state.status if status is invalid', () => {
      const STATE = {
        status: 'NORMAL'
      };

      mutations.setStatus(STATE, 'WRONG');

      expect(STATE.status).toBe('NORMAL');
    });
  });
});
